.form-one {
  .form-group {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin: 0;

    @media (min-width: 576px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .form-control {
    border: none;
    width: auto;
    height: auto;
    border-radius: 0;
    padding: 0;
  }

  .form-control-full {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    display: block;
    width: 100%;
    height: 73px;
    border-radius: 36.5px;
    background-color: #f1f1f1;
    color: #7e7e7e;
    font-size: 16px;
    font-weight: 500;
    border: none;
    outline: none;
    padding-left: 30px;
  }

  textarea {
    border-radius: 30px;
    height: 195px;
    padding-top: 20px;
  }

  .thm-btn {
    padding: 24.5px 57.5px;

    &:hover {
      background-color: $thm-primary;
      color: #fff;
    }
  }
}
