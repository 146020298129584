.pricing-one {
    padding-top: 80px;
    padding-bottom: 80px;

    @include large-tablet {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .row {
        --bs-gutter-y: 30px;
    }

}

.pricing-one__tab-title {
    margin-bottom: 50px;
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    justify-content: center;
    align-items: center;

    @include tablet {
        grid-template-columns: repeat(4, 1fr);
    }

    &-item {
        border: 1px solid $thm-border;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        min-height: 70px;
        cursor: pointer;
        transition: all 500ms ease;
        position: relative;
        display: flex;

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 48px;
            height: 10px;
            background-image: url(../images/shapes/price-tab-arrow.png);
            visibility: hidden;
            opacity: 0;
            transition: all 500ms ease;
        }

        &:hover,
        &.active-item {
            background-color: $thm-secondary;
            border-color: $thm-secondary;

            &::before {
                opacity: 1;
                visibility: visible;
            }

            p,
            i {
                color: #fff;
            }
        }

        i {
            font-size: 64px;
            color: $thm-primary;
            transition: all 500ms ease;
        }

        p {
            margin: auto;
            line-height: 1;
            font-size: 15px;
            font-weight: 800;
            color: $thm-primary;
            transition: all 500ms ease;
        }
    }
}

.price-card {
    background-color: $thm-gray;
    text-align: center;
    padding: 20px;
    // padding-top: 50px;

    &__name {
        line-height: 1;
        color: $thm-color;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 10px;
    }

    &__amount {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        color: $thm-black;
        position: relative;
        line-height: 1;
        font-weight: 400;
        margin: 0;
        margin-bottom: 22px;

        &-symbol {
            position: absolute;
            font-size: 35px;
            top: 0;
            right: 100%;
        }

        &-fraction {
            position: absolute;
            font-size: 20px;
            top: 0;
            left: 100%;
        }
    }

    &__duration {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        color: #fff;
        background-color: $thm-primary;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: -15px;
        position: relative;
        z-index: 10;

        i {
            font-size: 14px;
            margin-right: 10px;
        }
    }

    &__list {
        margin-bottom: 0;
        width: 100%;
        max-width: 230px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 35px;
        margin-bottom: 20px;

        li {
            text-align: left;
            position: relative;
            padding-left: 30px;
            font-size: 16px;
            font-weight: 500;

            +li {
                margin-top: 10px;
            }

            >i {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                font-size: 14px;

                &.fa-check {
                    color: $thm-base;
                }

                &.fa-times {
                    color: $thm-color;
                }
            }
        }
    }

    &__bottom {
        background-color: #fff;
        padding-bottom: 30px;
    }

    .thm-btn {
        font-size: 18px;
        font-weight: 800;
        padding: 10px 28px;

        &:hover {
            background-color: $thm-black;
            color: #fff;
        }
    }

    .price-line-desc{
        width: 100%;
        height: 100%;
        p{
            margin: 0;
            text-align: left;
        }
    }

}