.about-one {
    padding-top: 80px;
    padding-bottom: 80px;

    @include large-tablet {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .img-fluid {
        margin-bottom: 30px;
        display: block;

        @include large-tablet {
            margin-bottom: 50px;
        }
    }

    &__highlighted {
        color: $thm-base;
        font-size: 24px;
        margin: 0;
    }

    &__text {
        margin: 0;
        color: $thm-color;
        font-size: 18px;
        line-height: 34px;
        margin-top: 35px;
    }

    .section-title {
        @include large-tablet {
            margin-bottom: 0;
        }
    }
}

.about-two {
    padding-top: 80px;
    padding-bottom: 80px;

    @include large-tablet {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &__content {
        margin-top: 50px;
        padding-right: 0;

        @include large-tablet {
            margin-top: 0;
        }

        @include laptop {
            padding-right: 50px;
        }
    }

    .section-title {
        margin-bottom: 40px;
    }

    &__summery {
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
        color: $thm-color;
    }

    &__list {
        margin: 0;
        padding-left: 20px;
        position: relative;
        margin-top: 28px;
        margin-bottom: 28px;

        padding-left: 0;

        @include laptop {
            margin-top: 24px;
            margin-bottom: 32px;
        }

        &::before {
            content: '';
            width: 2px;
            height: calc(100% - 16px);
            background-color: $thm-base;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            display: none;
        }

        li {
            position: relative;
            padding-left: 26px;
            font-size: 18px;
            font-weight: 500;
            color: $thm-primary;

            +li {
                margin-top: 7px;
            }


            >i {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                font-size: 16px;
                color: $thm-secondary;
                top: 10px;
                transform: translateY(0%);
            }
        }
    }

    .section-separator {
        margin-bottom: 30px;
    }

    &__phone {
        display: flex;
        align-items: center;

        >i {
            flex-shrink: 0;
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 28px;
            color: $thm-black;
            background-color: $thm-gray;
            border-radius: 50%;
            margin-right: 10px;
        }

        &-content {
            display: flex;
            flex-direction: column;

            span {
                display: block;
                font-size: 16px;
                font-weight: 500;
                color: $thm-color;
            }

            a {
                color: $thm-black;
                font-weight: 500;
                font-size: 24px;
                transition: all 500ms ease;
            }
        }
    }

    &__icon {
        font-size: 62px;
        color: $thm-primary;
        margin-right: 20px;
        position: relative;
        top: 2px;

        &-box {
            display: flex;
            align-items: center;

            @include laptop {
                margin-top: 40px;
            }
        }

        &-content {
            color: $thm-base;
            font-size: 24px;
            line-height: 34px;
        }
    }

    .thm-btn {
        font-size: 18px;
        font-weight: 800;
        padding: 20px 48px;
        margin-bottom: 20px;
        margin-top: 30px;

        @include laptop {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 30px;
        }

        &:hover {
            background-color: $thm-black;
            color: #fff;
        }
    }

    &__button-box {
        @include laptop {
            display: flex;
            align-items: center;
            margin-top: 40px;
        }
    }

    &__image {
        position: relative;

        img {
            clip-path: polygon(0 0, 100% 0, 92% 101%, 0% 101%);
        }

        &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: $thm-base;
            width: 100px;
            height: 89px;
            clip-path: polygon(0 0, 100% 0, 70.5% 101%, 0% 101%);
            bottom: 40px;
            z-index: 10;
            -webkit-animation-name: shapeMover;
            animation-name: shapeMover;
            -webkit-animation-duration: 9s;
            animation-duration: 9s;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
    }
}

.about-three {
    padding-top: 80px;
    padding-bottom: 80px;

    @include large-tablet {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &__image {
        display: inline-block;
        position: relative;
        margin-bottom: 50px;

        &::before {
            content: '';
            position: absolute;
            top: 30px;
            left: 0;
            right: -12px;
            bottom: 0;
            background-color: $thm-base;
            clip-path: polygon(0 0, 100% 0, 97% 100%, 0% 100%);
        }

        img {
            clip-path: polygon(0 0, 97% 0, 100% 100%, 0% 100%);
        }
    }

    &__content {
        @include tablet {
            padding-right: 60px;
        }
    }

    .section-title {
        margin-bottom: 40px;
    }

    &__summery {
        color: $thm-base;
        font-size: 20px;
        font-weight: 500;
        line-height: 34px;
    }

    &__counter {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        margin: 0;
        margin-top: 50px;

        @include large-mobile {
            grid-template-columns: repeat(2, 1fr);
        }

        &-item {
            background-color: $thm-gray;
            display: flex;
            align-items: center;
            padding-right: 20px;
            padding-left: 30px;
            padding-top: 30px;
            padding-bottom: 30px;
            justify-content: center;

            @include large-mobile {
                justify-content: flex-start;
            }

            >i {
                font-size: 58px;
                color: $thm-base;
                display: block;
                margin-right: 20px;
                position: relative;
                top: 5px;
            }

        }

        &-number {
            margin: 0;
            color: $thm-primary;
            font-size: 34px;
            line-height: 1;
            font-weight: 800;
            font-family: $thm-font !important;

            @include tablet {
                font-size: 44px;
            }
        }

        &-text {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            color: $thm-color;
            line-height: 1;
            margin-top: 5px;
        }
    }
}

.about-four {
    background-color: $thm-gray;
    padding-top: 80px;
    padding-bottom: 80px;

    @include laptop {
        padding-top: 50px;
        padding-bottom: 0;
    }

    &__image {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            right: -30px;
            bottom: 50%;
            transform: translateY(50%);
            background-color: $thm-base;
            width: 100px;
            height: 89px;
            clip-path: polygon(0 0, 100% 0, 70.5% 101%, 0% 101%);
            z-index: 10;
            -webkit-animation-name: shapeMover;
            animation-name: shapeMover;
            -webkit-animation-duration: 9s;
            animation-duration: 9s;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }

        img {
            max-width: 100%;
            clip-path: polygon(0 0, 100% 0, 94% 101%, 0% 101%);

            @include laptop {
                float: right;
                max-width: none;
            }
        }

        &-text {
            background-color: $thm-secondary;
            padding: 50px;
            color: #fff;
            font-weight: 800;
            font-size: 20px;
            line-height: 30px;
            text-align: center;

            @include large-tablet {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                max-width: 300px;
                padding: 20px 30px;
                text-align: left;
            }

            @include laptop {
                max-width: 335px;
                padding: 40px 50px;
                right: -30px;
            }

            a {
                color: #fff;
                font-size: 16px;
                font-weight: 800;
                transition: all 500ms ease;

                &:hover {
                    color: $thm-primary;
                }

                @include laptop {
                    margin-top: 15px;
                    display: inline-block;
                }
            }
        }
    }

    .section-title {
        margin-bottom: 40px;
    }

    &__content {
        padding-top: 50px;

        @include laptop {
            padding-left: 70px;
        }
    }

    &__text {
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
    }

    &__list {
        margin-bottom: 0;
        margin-top: 35px;

        &-item {
            padding-left: 26px;
            position: relative;
            font-size: 20px;
            font-weight: 600;
            color: $thm-primary;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            >i {
                font-size: 16px;
                color: $thm-secondary;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }

    .thm-btn {
        font-size: 18px;
        font-weight: 800;
        padding: 20px 48px;
        margin-bottom: 20px;
        margin-top: 30px;

        @include laptop {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 0px;
        }

        &:hover {
            background-color: $thm-black;
            color: #fff;
        }
    }

    .about-two__button-box {
        @include laptop {
            flex-direction: row-reverse;
        }
    }

    .about-two__phone {
        margin-left: 0;

        @include laptop {
            margin-right: 40px;
        }
    }

    .about-two__phone>i {
        background-color: #fff;
    }
}


.about-five {
    padding-top: 80px;
    position: relative;

    @include large-tablet {
        padding-top: 100px;
    }

    .image-layer {
        position: absolute;
        top: 0;
        left: 0;
    }

    .container {
        position: relative;
        z-index: 10;
    }

    &__images {
        margin-bottom: 50px;

        @include large-tablet {
            margin-bottom: 0;
        }

        img {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .section-title {
        margin-bottom: 40px;
    }

    &__content {
        @include laptop {
            padding-right: 70px;
        }
    }

    &__summery {
        color: $thm-base;
        font-size: 24px;
        line-height: 34px;
        font-weight: 500;
        margin-bottom: 35px;
    }

    &__text {
        font-size: 18px;
        font-weight: 500;
        line-height: 34px;
        margin-bottom: 45px;
    }

    &__progress {
        display: flex;
        align-items: center;

        &-wrap {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 30px;

            @include tablet {
                grid-template-columns: repeat(2, 220px);
            }
        }

        &-box {
            position: relative;
            display: inline-block;

            canvas {
                transform: rotate(90deg);
            }

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                transform: translateY(-50%) translateX(-50%);
                font-size: 20px;
                font-weight: 800;
                line-height: 1;
                color: $thm-secondary;
            }
        }

        h3 {
            margin: 0;
            font-size: 18px;
            line-height: 30px;
            font-weight: 800;
            color: $thm-black;
            width: 70px;
            margin-left: 20px;
        }
    }
}