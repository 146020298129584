.help-search {
    padding-top: 80px;
    padding-bottom: 80px;
    text-align: center;

    @include large-tablet {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &__title {
        margin: 0;
        font-size: 44px;
        font-weight: 800;
        color: $thm-black;
    }

    .search-form {
        width: 100%;
        max-width: 830px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        text-align: left;
        margin-top: 40px;

        input[type=search],
        input[type=text] {
            width: 100%;
            background-color: $thm-gray;
            height: 84px;
            border: none;
            outline: none;
            display: block;
            font-weight: 600;
            font-size: 16px;
            color: $thm-color;
            padding-left: 30px;
            padding-right: 30px;
        }

        button[type=submit] {
            padding: 0;
            width: auto;
            border: none;
            outline: none;
            font-size: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 30px;
            color: $thm-base;
            transition: all 500ms ease;

            &:hover {
                color: $thm-secondary;
            }
        }
    }
}

.help-one {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: $thm-gray;

    @include large-tablet {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .row {
        margin-top: -30px;

        [class*=col-] {
            margin-top: 30px;
        }
    }

    &__card {
        background-color: #fff;
        border: 1px solid $thm-border;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 30px;

        &-icon {
            font-size: 62px;
            color: $thm-base;
        }

        &-title {
            margin: 0;
            color: $thm-black;
            font-weight: 800;
            font-size: 24px;
            margin-top: 22px;
            margin-bottom: 20px;

            a {
                color: inherit;
                transition: all 500ms ease;

                &:hover {
                    color: $thm-base;
                }
            }
        }

        &-text {
            margin: 0;
            font-size: 18px;
            line-height: 30px;
            font-weight: 500;
            color: $thm-color;
            max-width: 320px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 22px;
        }

        &-more {
            width: 63px;
            height: 63px;
            background-color: $thm-gray;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $thm-black;
            font-size: 20px;
            transition: all 500ms ease;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto;

            &:hover {
                color: #fff;
                background-color: $thm-base;
            }
        }
    }
}