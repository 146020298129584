.google-map__default {
  width: 100%;
  iframe {
    display: block;
    border: none;
    outline: none;
    width: 100%;
    height: 550px;
  }
}
