.testimonials-one {
    padding-left: 15px;
    padding-right: 15px;

    margin-top: -80px;
    margin-bottom: 15px;

    @include large-tablet {
        margin-top: -100px;
        margin-bottom: 30px;
    }

    &__home-two {
        margin-top: -150px;

        @include large-tablet {
            margin-top: -150px;
        }
    }

    &__boxed {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 1370px;
        background-color: $thm-primary;
        background-image: url(../images/shapes/testimonials-bg-1-1.png);
        background-repeat: no-repeat;
        background-position: center bottom;
        position: relative;

        padding-top: 80px;
        padding-bottom: 80px;

        @include large-tablet {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 30px;
            background-color: $thm-secondary;
            z-index: -1;

            left: 15px;
            right: 15px;
            bottom: -15px;

            @include large-tablet {
                left: 30px;
                right: 30px;
                bottom: -30px;
            }
        }
    }

    &__thumb {
        width: 100%;
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 17px;

        img {
            width: 100%;
            border-radius: 50%;
            border: 4px solid #fff;
            transform: scale(.8);
            cursor: pointer;
            transition: transform 500ms ease, border 500ms ease;
        }

        .swiper-slide-thumb-active {
            img {
                transform: scale(1);
                border-color: $thm-base;
            }
        }
    }

    &__carousel {
        text-align: center;


    }

    &__text {
        margin: 0;
        font-weight: 600;
        color: #fff;
        max-width: 921px;
        margin-left: auto;
        margin-right: auto;

        font-size: 20px;
        line-height: 38px;

        @include large-tablet {
            font-size: 24px;
            line-height: 46px;
        }
    }

    &__name {
        margin: 0;
        color: #fff;
        font-weight: 800;
        margin-top: 40px;
        margin-bottom: 5px;

        margin-top: 20px;

        @include large-tablet {
            font-size: 24px;
        }
    }

    &__designation {
        margin: 0;
        display: block;
        line-height: 1;
        font-size: 16px;
        font-weight: 600;
        color: $thm-secondary;
    }
}