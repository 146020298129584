$thm-font: var(--thm-font);
$heading-font: var(--heading-font);
$special-font: var(--special-font);

$body-size: 18px;
$letter-space: 0em;
$body-line-height: 30px;
$heading-size: 20px;
$heading-size2: 36px;
$heading-size3: 46px;
$heading-size4: 100px;