.no-after{
  &::after {
    content: '';
    width: 0 !important;
    height: 0 !important;
    background-image: none;
  }
}

.page-header {
  background-color: $thm-base;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 419px;
    background-image: url(../images/shapes/top-shadow.png);
    position: absolute;
    top: 0;
    left: 0;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .container {
    position: relative;
    z-index: 10;

    padding-top: 315px;
    padding-bottom: 155px;

    @media(max-width: 991px) {
      padding-top: (315-65)*1px;
      padding-bottom: 110px;
    }

    @media(max-width: 768px) {
      padding-top: (315-120)*1px;
    }


    h2 {
      margin: 0;
      color: #fff;
      font-weight: 800;
      font-size: 44px;

      @media (max-width: 480px) {
        font-size: 32px;
      }
    }
  }

  .container-empty {
    position: relative;
    z-index: 10;

    padding-top: 100px;
    padding-bottom: 90px;

    @media(max-width: 991px) {
      padding-top: 175px;
      padding-bottom: 70px;
    }

    @media(max-width: 768px) {
      padding-top: 40px;
    }


    h2 {
      margin: 0;
      color: var(--thm-black);
      font-weight: 800;
      font-size: 44px;

      @media (max-width: 480px) {
        font-size: 32px;
      }
    }
  }
}

.thm-breadcrumb {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 15px;
  background-color: #fff;
  padding: (51-16)/2*1px 40px;

  li {
    font-size: 16px;
    font-weight: 500;
    color: $thm-black;
    line-height: 1;
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    a {
      color: inherit;
    }

    span {
      display: block;
      line-height: 1;
      color: $thm-secondary;
    }
  }
}