.feature-lists {
    padding-bottom: 80px;
    margin-top: -10px;

    @include large-tablet {
        margin-top: -10px;
        padding-bottom: 100px;
    }

    &__boxed {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 1370px;
        background-color: $thm-primary;
        position: relative;

        padding-top: 80px;
        padding-bottom: 80px;

        @include large-tablet {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    .jarallax-img {
        opacity: 0.08;
    }

    &__wrap {
        padding-top: 40px;

        @include large-tablet {
            display: flex;
            flex: 0 0 auto;
            justify-content: space-between;
            padding-top: 10px;
        }
    }

    &__list {
        margin: 0;
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include laptop {
            margin-bottom: 0;
        }

        li {
            position: relative;
            padding-left: 26px;
            font-size: 18px;
            font-weight: 600;
            color: #fff;

            +li {
                margin-top: 10px;
            }

            >i {
                position: absolute;
                top: 10px;
                left: 0;
                font-size: 16px;
                color: $thm-secondary;
            }
        }
    }

    .section-title__title {
        color: #fff;
    }
}

.feature-one {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    background-color: $thm-gray;

    @include large-tablet {
        padding-top: 100px;
        padding-bottom: 100px;
        text-align: center;
    }

    @include laptop {
        padding-bottom: 0;

        &::before {
            content: '';
            width: 100%;
            height: 185px;
            background-color: #fff;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }



    &__content {
        text-align: left;
        position: relative;

        @include large-tablet {
            margin-left: 50px;
            margin-right: -50px;
        }

        @include laptop {
            margin-left: 0;
            margin-right: 0;
            padding-left: 70px;
        }
    }

    .section-title {
        margin-bottom: 40px;
    }

    &__summery {
        font-size: 18px;
        line-height: 34px;
        font-weight: 500;
        margin-bottom: 45px;
    }

    &__list {
        margin: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 35px;
        margin-bottom: 45px;

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
        }

        li {
            position: relative;
            padding-left: 56px;
            background-color: #fff;
            box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
            font-size: 18px;
            font-weight: 500;
            padding-top: 15px;
            padding-bottom: 15px;
            color: $thm-black;


            >i {
                color: $thm-secondary;
                font-size: 16px;
                position: absolute;
                top: 50%;
                left: 30px;
                transform: translateY(-50%);
            }
        }
    }

    &__progress {
        margin-bottom: 100px;

        &-item {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-title {
            margin: 0;
            color: $thm-black;
            font-size: 20px;
            font-weight: 600;
            display: block;
            margin-bottom: 7px;
        }

        &-bar {
            position: relative;
            width: 100%;
            height: 13px;
            background-color: #fff;
            border-radius: 13/2*1px;
        }

        &-main {
            position: absolute;
            left: 0;
            height: 100%;
            top: 0;
            border-radius: 13/2*1px;
            background-color: $thm-base;
        }

        &-count {
            font-size: 16px;
            font-weight: 500;
            color: $thm-color;
            position: absolute;
            bottom: calc(100% + 5px);
            right: 0;
        }
    }

    &__counter {
        background-color: $thm-primary;
        padding: 30px;
        text-align: center;

        @include laptop {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            text-align: left;
            padding: 35px;
            padding-left: 60px;
        }

        &-icon {
            width: 115px;
            height: 115px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 62px;
            background-color: $thm-secondary;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            flex-shrink: 0;

            @include laptop {
                margin-bottom: 0;
                margin: 0;
            }
        }

        &-title {
            margin: 0;
            font-size: 40px;
            font-weight: 500;
            color: #fff;
            line-height: 1;
            margin-bottom: 20px;
            font-family: $thm-font !important;

            span {
                font-family: inherit;
            }

            @include laptop {
                margin-bottom: 10px;
                font-size: 50px;
            }

        }

        &-text {
            margin: 0;
            line-height: 1;
            color: $thm-text-dark;
            font-size: 20px;
            font-weight: 500;

        }

        &-content {
            @include laptop {
                margin-right: auto;
            }
        }
    }

}

.feature-two {
    background-color: $thm-primary;
    background-image: url(../images/shapes/features-shape-1.png);
    background-repeat: no-repeat;
    background-position: top left;
    padding-top: 80px;
    padding-bottom: 80px;

    @include laptop {
        padding-top: 0;
        padding-bottom: 25px;
    }

    &__image {
        position: relative;
        margin-bottom: 50px;


        @include laptop {
            margin-bottom: 0;
        }


        img {
            max-width: 100%;

            @include laptop {
                max-width: none;
                margin-left: 70px;
            }
        }

        &-line-left {
            border-left: 1px dashed $thm-border;
            width: 0;
            height: calc(100% - 60px);
            top: 50%;
            transform: translateY(-50%);
            left: 15px;
            position: absolute;
        }

        &-line-right {
            border-right: 1px dashed $thm-border;
            width: 0;
            height: calc(100% - 60px);
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            position: absolute;
        }

        &-line-top {
            border-top: 1px dashed $thm-border;
            height: 0;
            width: calc(100% - 50px);
            left: 50%;
            transform: translateX(-50%);
            top: 15px;
            position: absolute;
        }

        &-line-bottom {
            border-bottom: 1px dashed $thm-border;
            height: 0;
            width: calc(100% - 50px);
            left: 50%;
            transform: translateX(-50%);
            bottom: 15px;
            position: absolute;
        }

        &-text {
            width: 172px;
            height: 172px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 18px;
            line-height: 26px;
            font-weight: 500;
            color: $thm-black;
            position: absolute;
            flex-direction: column;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            background-color: #fff;
            padding: 15px;


            &::after {
                content: '';
                width: 60px;
                height: 50px;
                background-color: #dfdfdf;
                position: absolute;
                top: 100%;
                left: 0;
                clip-path: polygon(0 0, 100% 100%, 100% 0);
                display: none;

                @include laptop {
                    display: block;
                }
            }

            @include laptop {
                top: 100px;
                left: 10px;
                transform: translateY(0%) translateX(0%);
            }

            span {
                font-size: 54px;
                color: $thm-base;
                display: block;
                line-height: 1;
            }
        }
    }

    .section-title__title {
        color: #fff;
    }

    &__list {
        @include tablet {
            margin-left: -30px;
        }

        @include laptop {
            padding-right: 35px;
        }

        &-item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            @include tablet {
                display: flex;
                align-items: flex-start;
                padding: 30px;
                margin-bottom: 0;
                transition: all 500ms ease;

                &:hover {
                    background-color: rgba($thm-black-rgb, .15);

                    .feature-two__list-icon {
                        color: $thm-base;
                    }
                }
            }
        }

        &-icon {
            font-size: 62px;
            transition: all 500ms ease;
            color: $thm-secondary;

            @include tablet {
                margin-right: 20px;
            }
        }

        &-title {
            margin: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            margin-top: 20px;
            margin-bottom: 10px;

            @include tablet {
                margin-top: -3px;
            }
        }

        &-text {
            margin: 0;
            color: $thm-text-dark;
        }
    }

    &__content {
        @include laptop {
            padding-top: 100px;
        }
    }
}