$vehicle-card-height: 185px;

.vehicle-modal-title {
    margin: 0;
    font-weight: 800;
    color: $thm-black;
    margin-bottom: 5px;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--thm-primary);

    @include tablet {
        font-size: 25px;
    }
}

.vehicle-add {
    margin-top: 20px;
    border: 5px dashed var(--thm-gray);
    height: $vehicle-card-height;
    transition: 200ms;
    display: flex;

    * {
        transition: 100ms !important;
        margin: auto;
    }
}

.vehicle-add:hover {
    cursor: pointer;

    * {
        color: var(--thm-secondary);
    }
}

.vehicle-card {
    position: relative;
    margin-top: 20px;
    background-color: $thm-gray;
    text-align: left;
    padding: 20px;
    padding-top: 20px;
    height: $vehicle-card-height;

    &__name {
        line-height: 1;
        color: $thm-color;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 10px;
    }

    &__plate {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 150px;
        margin-left: auto;
        margin-right: auto;
        color: #fff;
        background-color: $thm-primary;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.5px;
        margin-bottom: -15px;
        position: relative;
        z-index: 10;

        i {
            font-size: 14px;
            margin-right: 10px;
        }
    }

    &__category {
        font-size: 14px;
        text-transform: capitalize;
        position: absolute;
        right: 13px;
        color: var(--thm-primary);
    }

    &__list {
        margin-bottom: 0;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 35px;

        li {
            position: relative;
            padding-left: 30px;
            font-size: 20px;
            font-weight: 500;

            +li {
                margin-top: 15px;
            }

            &.added {
                font-size: 14px;
                color: var(--thm-text-dark);
            }
        }
    }

    &__bottom {
        background-color: #fff;
        padding-bottom: 20px;
    }

    &__add {
        height: 100%;
        background-color: white;
        display: flex;

        * {
            margin: auto;
        }
    }

    &__maker {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;

        p {
            margin: 0 !important;
        }

        img {
            margin-right: 10px;
            width: 35px;
            height: 30px;
            object-fit: contain;
            background-color: #ededed;
            border-radius: 8px;
        }
    }

    .thm-btn {
        font-size: 18px;
        font-weight: 800;
        padding: 20px 48px;

        &:hover {
            background-color: $thm-black;
            color: #fff;
        }
    }

    .delete-vehicle-btn {
        color: var(--thm-danger);
        font-size: 14px;
        bottom: 10px;
        right: 30px;
        position: absolute;
        text-decoration: underline;
        cursor: pointer;
    }

    .vehicle-selected-icon {
        width: 40px;
        height: 40px;
        position: absolute;
        right: -10px;
        top: -10px;
        border-radius: 15px;
    }

}

.vehicle-card-select {
    cursor: pointer;
    transition: 500ms;
}

.vehicle-card-select:hover, .vehicle-card-selected {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
}