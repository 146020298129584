.main-slider {
    overflow: hidden;
    position: relative;

    .swiper-slide {
        position: relative;
        background-color: $thm-black;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../images/shapes/top-shadow.png);
            background-repeat: repeat-x;
            background-position: top center;
            z-index: 20;
        }

        .container {
            position: relative;
            z-index: 30;
        }
    }

    .image-layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.6;
        background-repeat: no-repeat;
        background-size: cover;
        transform: scale(1);
        transition: transform 7000ms ease;
    }

    .container {

        padding-bottom: 100px;
        padding-top: 196px;

        @include laptop {
            padding-top: 275px;
            padding-bottom: 190px;


        }


    }


    h2 {
        margin: 0;
        margin-bottom: 40px;
        color: #fff;
        font-weight: 800;
        opacity: 0;
        visibility: hidden;
        transform: translateY(120px);
        transition-delay: 1000ms;
        transition: transform 2000ms ease, opacity 2000ms ease;

        font-size: 48px;
        line-height: 58px;

        @include tablet {
            font-size: 58px;
            line-height: 68px;
        }

        @include large-tablet {
            font-size: 75px;
            line-height: 65px;
        }

        @include laptop {

            font-size: 70px;
            line-height: 100px;
        }

    }

    .thm-btn {
        font-size: 18px;
        font-weight: 800;
        padding: 20px 48px;

        &:hover {
            background-color: $thm-primary;
            color: #fff;
        }

        opacity: 0;
        transform: translateY(100px);
        transition-delay: 2500ms;
        transition: transform 2000ms ease,
        opacity 2000ms ease,
        color 500ms ease,
        background 500ms ease;
    }

    .swiper-slide-active {
        .image-layer {
            transform: scale(1.15);
        }

        .thm-btn,
        p,
        h2 {
            visibility: visible;
            opacity: 1;
            transform: translateY(0) translateX(0);
        }
    }
}


.main-slider__nav {
    width: 100%;
    max-width: 720px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    top: 54%;
    left: 50%;
    z-index: 100;
    transform: translateY(-50%) translateX(-50%);
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include large-tablet {
        max-width: 1200px;
    }

    @media (max-width: 425px) {
        display: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        z-index: 100;
        width: 63px;
        height: 63px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 50%;
        opacity: 1;
        margin: 0;
        text-align: center;
        transition: all 500ms ease;

        @media (max-width: 991px) {
            width: 48px;
            height: 48px;
            font-size: 18px;
        }

        &:hover {
            opacity: 0.3;
        }

        &::after {
            display: none;
        }
    }

    .swiper-button-prev {
        margin-bottom: 10px;

        @media (max-width: 991px) {
            margin-top: 10px;
        }
    }
}



.main-slider__nav-two {
    width: 100%;
    max-width: 1320px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translateY(-50%) translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 425px) {
        display: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        z-index: 100;
        width: 63px;
        height: 63px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 50%;
        opacity: 1;
        margin: 0;
        text-align: center;
        transition: all 500ms ease;

        @media (max-width: 991px) {
            width: 48px;
            height: 48px;
            font-size: 18px;
        }

        &:hover {
            opacity: .3;
        }

        &::after {
            display: none;
        }
    }

    .swiper-button-prev {
        margin-bottom: 10px;

        @media (max-width: 991px) {
            margin-top: 10px;
        }
    }
}


.main-slider-two {
    .swiper-slide::before {
        display: none;
    }

    .swiper-slide {
        .container {
            padding-top: 100px;
            padding-bottom: 100px;

            @include large-tablet {
                padding-top: 150px;
                padding-bottom: 150px;
            }

            @include laptop {
                padding-top: 200px;
                padding-bottom: 216px;
            }
        }
    }

    h2 {
        @include laptop {
            font-size: 100px;
            line-height: 90px;
        }
    }

    p {
        margin: 0;
        font-size: 20px;
        color: #fff;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transform: translateY(120px);
        transition-delay: 1500ms;
        transition: transform 2000ms ease, opacity 2000ms ease;

        &::after {
            content: '';
            width: 40px;
            height: 2px;
            background-color: $thm-secondary;
            margin-left: 10px;

            @include large-tablet {
                width: 56px;
            }
        }

        @include large-tablet {
            font-size: 24px;
        }
    }
}