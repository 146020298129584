.team-about {
    background-color: $thm-gray;
    padding-top: 80px;
    padding-bottom: 160px;

    @include large-tablet {
        padding-top: 100px;
        padding-bottom: 200px;
    }
}

.team-card {
    width: 100%;

    &__image {
        >img {
            width: 100%;
        }
    }

    &__social {
        width: 92.5%;
        background-color: $thm-base;
        padding-left: 40px;
        position: absolute;
        min-height: 35px;
        top: 0;
        left: 0;
        transform: translateY(-50%);
        padding-top: 5px;
        padding-bottom: 5px;
        clip-path: polygon(0 0, 100% 0, 86.5% 101%, 0% 101%);

        a {
            color: #fff;
            font-size: 16px;
            transition: all 500ms ease;

            &:hover {
                color: $thm-black;
            }

            +a {
                margin-left: 30px;
            }
        }
    }

    &__content {
        position: relative;
        padding: 30px 40px;
        padding-top: 47px;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
        background-color: #fff;

        h3 {
            margin: 0;
            font-size: 20px;
            color: $thm-black;
            font-weight: 600;
        }

        p {
            margin: 0;
            font-weight: 600;
            color: $thm-color;
        }

        &::after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: $thm-secondary;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scale(0, 1);
            transform-origin: left center;
            transition: transform 500ms ease;
        }
    }

    &:hover &__content::after {
        transform: scale(1, 1);
        transform-origin: right center;
    }

    &__row {
        --bs-gutter-y: 30px;
    }
}

.team-home-two {
    background-color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;

    @include large-tablet {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}