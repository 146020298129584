$thm-color: var(--thm-color);
$thm-color-rgb: var(--thm-color-rgb);
$thm-gray: var(--thm-gray);
$thm-gray-rgb: var(--thm-gray-rgb);
$thm-base: var(--thm-base);
$thm-base-rgb: var(--thm-base-rgb);
$thm-primary: var(--thm-primary);
$thm-primary-rgb: var(--thm-primary-rgb);
$thm-secondary: var(--thm-secondary);
$thm-secondary-rgb: var(--thm-secondary-rgb);
$thm-special: var(--thm-special);
$thm-special-rgb: var(--thm-special-rgb);
$thm-black: var(--thm-black);
$thm-black-rgb: var(--thm-black-rgb);
$thm-black2: var(--thm-black2);
$thm-black2-rgb: var(--thm-black2-rgb);
$thm-text-dark: var(--thm-text-dark);
$thm-text--dark-rgb: var(--thm-text-dark-rgb);
$thm-border: var(--thm-border);
$thm-border-rgb: var(--thm-border-rgb);