@mixin medium-mobile {
  @media (min-width: 376px) {
    @content;
  }
}
@mixin large-mobile {
  @media (min-width: 576px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin large-tablet {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin laptop {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin large-laptop {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin max-mobile {
  @media (max-width: 576px) {
    @content;
  }
}