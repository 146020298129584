.topbar {
  display: none;

  @include tablet {
    display: block;
  }

  &__inner {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $thm-black;
    padding-left: 30px;
    padding-right: 30px;
  }

  &__infos {
    display: flex;
    align-items: center;
    margin: 0;

    &-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &-link {
      color: $thm-text-dark;
      font-size: 15px;
      font-weight: 500;
      display: flex;
      align-items: center;
      transition: all 500ms ease;
      line-height: 1;

      i {
        font-size: 15px;
        color: $thm-base;
        margin-right: 10px;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;

    a {
      font-size: 16px;
      color: $thm-text-dark;
      line-height: 1;
      transition: all 500ms ease;

      &:hover {
        color: #fff;
      }

      +a {
        margin-left: 30px;
      }
    }

  }
}


.main-menu {
  position: relative;
  z-index: 91;

}

.main-menu,
.stricky-header {

  .main-menu__list,
  .main-menu__list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    align-items: center;
    display: none;

    @media (min-width: 1200px) {
      display: flex;
    }
  }



  .main-menu__list>li {
    padding-top: 39.5px;
    padding-bottom: 39.5px;
    position: relative;

  }

  .main-menu__list>li+li {
    margin-left: 42px;
  }

  .main-menu__list>li>a {
    font-family: $thm-font;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: $thm-color;
    position: relative;
    transition: all 500ms ease;

    &::before {
      content: '';
      width: 100%;
      height: 2px;
      background-color: currentColor;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: transform 500ms ease;
      transform: scale(0, 1);
      transform-origin: left center;
    }

  }

  .main-menu__list>li.current>a,
  .main-menu__list>li:hover>a {
    color: $thm-secondary;

    &::before {
      transform: scale(1, 1);
      transform-origin: right center;
    }
  }

  .main-menu__list li ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 220px;
    background-color: #fff;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    transition: 500ms ease;
    z-index: 99;
    box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  }

  .main-menu__list li:hover>ul {
    opacity: 1;
    visibility: visible;
  }

  .main-menu__list li ul li {
    flex: 1 1 100%;
    width: 100%;
    position: relative;
  }

  .main-menu__list li ul li+li {
    border-top: 1px solid RGBA($thm-black, 0.1);
  }

  .main-menu__list li ul li a {
    font-size: 16px;
    line-height: 30px;
    color: $thm-black;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 500ms;
  }


  .main-menu__list li ul li:hover>a {
    background-color: $thm-base;
    color: #fff;
  }

  .main-menu__list li ul li>ul {
    top: 0;
    left: 100%;
  }

  .main-menu__list li ul li>ul.right-align {
    top: 0;
    left: auto;
    right: 100%;
  }

  /* after third level no menu */
  .main-menu__list li ul li>ul ul {
    display: none;
  }

}




.stricky-header {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  transform: translateY(-120%);
  transition: transform 500ms ease, visibility 500ms ease;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 1199px) {
    display: none;
  }


  &.stricky-fixed {
    transform: translateY(0);
    visibility: visible;
  }

  .main-menu__inner {
    box-shadow: none;
    padding-right: 0;
  }
}

.mobile-nav__buttons {
  display: flex;
  margin-left: auto;
  margin-right: 10px;

  @include laptop {
    display: none;
  }

  a {
    font-size: 20px;
    color: $thm-black;
    cursor: pointer;

    +a {
      margin-left: 10px;
    }

    &:hover {
      color: $thm-base;
    }
  }
}

.main-menu .mobile-nav__toggler {
  font-size: 20px;
  color: $thm-black;
  cursor: pointer;
  transition: 500ms;
  margin-right: 20px;

  &:hover {
    color: $thm-base;
  }

  @include laptop {
    display: none;
  }
}


.main-menu {
  &__inner {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 276px;
    padding-right: 30px;
    background-color: #fff;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.35);

    @media(max-width: 1199px) {
      padding-left: 0px;
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $thm-primary;
    min-width: 256px;
    min-height: 129px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    clip-path: polygon(0 0, 100% 0, 86.5% 101%, 0% 101%);

    @media(max-width: 1199px) {
      min-height: auto;
      min-width: auto;
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      padding-left: 30px;
      padding-right: 60px;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    @media(max-width: 425px) {
      padding-right: 45px;
    }

  }

  &__right {
    margin-left: auto;
    border-left: 1px solid #ebf0f6;
    padding-left: 30px;
    display: flex;
    align-items: center;

    @media(max-width: 425px) {
      padding-left: 0;
    }
  }

  &__search,
  &__cart {
    font-size: 24px;
    color: $thm-black;
    margin-right: 30px;
    transition: all 500ms ease;

    &:hover {
      color: $thm-secondary;
    }

    @media(max-width: 1199px) {
      margin-right: 20px;
      font-size: 20px;
    }
  }

  &__cart {
    @media(max-width: 767px) {
      margin-right: 0;
    }
  }

  .thm-btn {
    display: none;
    border: 1px solid var(--thm-base);

    @include tablet {
      display: inline-block;
    }

    &:hover {
      background-color: white;
      color: var(--thm-base);
      border: 1px solid var(--thm-base);
    }

    &-danger {
      padding-left: 22px;
      padding-right: 22px;
      background-color: var(--thm-danger);
      border: 1px solid var(--thm-danger);
    }
    &-danger:hover {
      background-color: white;
      color: var(--thm-danger);
      border: 1px solid var(--thm-danger);
    }
  }
}

.main-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 91;
  width: 100%;
}

.main-header-two {
  position: relative;

  .container-fluid {
    padding: 0;
  }

}

.main-menu__two {
  .container-fluid {
    padding: 0;
    padding-top: 30px;
    padding-bottom: 30px;

    @include laptop {
      padding: 0;
    }
  }

  .main-menu__inner {
    box-shadow: none;
  }

  .main-menu__list>li>a {
    color: $thm-black;
  }

  .main-menu__logo {
    position: relative;
    background-color: #fff;
    clip-path: none;
    top: auto;
    min-height: auto;
    padding: 0;
    min-width: auto;
    margin-right: 50px;
  }

  .main-menu__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}