.client-carousel {
  padding-top: 80px;
  padding-bottom: 80px;

  &__has-border-top {
    border-top: 1px solid #e9e7e2;
  }

  &__has-top-shadow {
    box-shadow: 0px -1px 0px 0px RGBA(227, 227, 227, 0.004);
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
  }

  img {
    max-width: 100%;
  }
}

.client-carousel__about-page {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 16px;
    position: absolute;
    bottom: -16px;
    left: 0;
    z-index: 10;
    transform: rotate(0deg);
    background-size: auto;

    @media (min-width: 1700px) {
      background-size: 100%;
      bottom: -15px;
    }
  }

  .container {
    position: relative;
  }
}
