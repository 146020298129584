:root {
  --thm-font: "Barlow", sans-serif;
  --heading-font: "Barlow", sans-serif;
  --special-font: "Barlow", sans-serif;
  --thm-color: #727a84;
  --thm-color-rgb: 114, 122, 132;
  --thm-gray: #eef3f7;
  --thm-gray-rgb: 238, 243, 247;
  --thm-base: #00974b;
  --thm-base-rgb: 0, 151, 75;
  --thm-primary: #02489d;
  --thm-primary-rgb: 2, 72, 157;
  --thm-secondary: #3fbfef;
  --thm-secondary-rgb: 63, 191, 239;
  --thm-special: #5366c2;
  --thm-special-rgb: 83, 102, 194;
  --thm-black: #072040;
  --thm-black-rgb: 7, 32, 64;
  --thm-black2: #383a39;
  --thm-black2-rgb: 56, 58, 57;
  --thm-text-dark: #afc0d6;
  --thm-text-dark-rgb: 175, 192, 214;
  --thm-border: #e2e9f0;
  --thm-border-rgb: 226, 233, 240;

  --thm-danger: #dc3545;

}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

body {
  font-family: $thm-font;
  color: $thm-color;
  font-size: $body-size;
  line-height: $body-line-height;
  font-weight: 500;
}

body.locked {
  overflow: hidden;
}

a {
  color: $thm-base;
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  color: $thm-black;

  br {
    @media (max-width: 575px) {
      display: none;
    }
  }
}

p {
  br {
    @media (max-width: 575px) {
      display: none;
    }
  }
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.background-base {
  background-color: $thm-base;
}

.background-primary {
  background-color: $thm-primary;
}

.background-secondary {
  background-color: $thm-secondary;
}

.background-special {
  background-color: $thm-special;
}

.background-black {
  background-color: $thm-black;
}

.background-black-2 {
  background-color: $thm-black2;
}

.thm-text-dark {
  color: $thm-text-dark;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.section-separator {
  border-color: $thm-border;
  border-width: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

.thm-btn {
  @include thmBtn;

  i {
    margin-left: 7px;
  }
}

.bootstrap-select {
  .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $thm-base;
  }
}

// tiny slider button customize
.tns-outer {
  .tns-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    button {
      width: 45px;
      height: 45px;
      border: 2px solid #f4f4f4;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $thm-color;
      border-radius: 50%;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.section-title {
  margin-top: -9px;
  margin-bottom: 50px;

  &__tagline {
    display: inline-flex;
    color: $thm-secondary;
    font-size: 18px;
    font-weight: 500;
    align-items: center;
    line-height: 1;

    &::after {
      content: '';
      background-color: currentColor;
      width: 56px;
      height: 2px;
      margin-left: 10px;
      margin-top: 3px;
    }
  }

  &__title {
    margin: 0;
    font-weight: 800;
    color: $thm-black;
    margin-bottom: -9px;
    font-size: 32px;

    @include tablet {
      font-size: 44px;
    }
  }

  &__subtitle {
    margin: 0;
    font-weight: 800;
    color: $thm-black;
    margin-bottom: -9px;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--thm-primary);

    @include tablet {
      font-size: 25px;
    }
  }
}


.ul-list-one {
  margin-bottom: 0;

  li {
    position: relative;
    padding-left: 45px;
    font-size: $body-size;
    font-weight: 500;
    color: $thm-black;

    @media (min-width: 481px) {
      font-size: $heading-size;
    }

    &::before {
      content: "\e907";
      color: $thm-secondary;
      font-size: 26px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-family: "azino-icon";
    }
  }
}

.tab-box__content {
  display: none;
}

.tab-box__content.active-item {
  display: block;
}

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;

  &__image {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}

/* scroll to top */
.scroll-to-top {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: $thm-base;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
  border-radius: 50%;
  transition: all 0.4s ease;
}

.scroll-to-top i {
  color: #ffffff;
  font-size: 18px;
  line-height: 45px;
}

.scroll-to-top:hover {
  background-color: $thm-black;
}

.scroll-to-top:hover i {
  color: #fff;
}

/* post paginations */

.post-pagination {
  margin-bottom: 0;
  margin-top: 0px;

  @media (min-width: 992px) {
    margin-top: 0px;
  }

  a {
    display: flex;
    width: 45px;
    height: 45px;
    background-color: #eff2f6;
    align-items: center;
    justify-content: center;
    color: $thm-color;
    font-size: 16px;
    font-weight: 500;
    border-radius: 50%;
    transition: 500ms ease;

    @media (min-width: 992px) {
      width: 60px;
      height: 60px;
      font-size: 18px;
    }

    &:hover {
      background-color: $thm-base;
      color: #fff;
    }
  }

  li:first-child a {
    background-color: $thm-base;
    color: #fff;
  }

  li:last-child a {
    background-color: $thm-black;
    color: #fff;
  }

  li+li {
    margin-left: 10px;
  }
}


.google-map__contact-page {
  iframe {
    width: 100%;
    height: 504px;
    border: none;
    display: block;
  }
}