.main-footer {
    background-color: $thm-black;
    position: relative;

    .image-layer {
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        width: 884px;
        height: 587px;
        opacity: 0.1;
    }

    .container {
        position: relative;
    }
}

.upper-footer {
    padding-top: 140px;
    padding-bottom: 70px;
}

// footer widgets

.footer-widget {
    margin-bottom: 30px;

    &__title {
        margin: 0;
        color: #fff;
        font-weight: 800;
        font-size: 20px;
        margin-bottom: 38px;
    }

    &__links &__title {
        margin-bottom: 36px;
    }

    &__logo {
        img {
            display: block;
            margin-top: -(67/2)*1px;
        }
    }

    &__about &__text {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    &__text {
        margin: 0;
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        color: $thm-text-dark;
    }

    &__contact &__text,
    &__mailchimp &__text {
        line-height: 26px;
    }

    &__contact &__text {
        max-width: 190px;
    }

    &__mailchimp &__text {
        max-width: 250px;
    }

    &__social {
        display: flex;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: $thm-primary;
            color: #fff;
            width: 43px;
            height: 43px;
            font-size: 16px;
            transition: 500ms;
            border-radius: 50%;
            margin-right: 10px;

            &:last-of-type {
                margin-right: 0;
            }

            &:hover {
                background-color: $thm-secondary;
                color: #fff;
            }
        }
    }

    &__links {
        &-wrap {
            display: flex;
            justify-content: flex-start;

            ul {
                margin: 0;
                flex: 1 auto;

                li {
                    +li {
                        margin-top: 6px;
                    }

                    a {
                        font-size: 16px;
                        font-weight: 500;
                        display: block;
                        color: $thm-text-dark;
                        transition: 500ms ease;

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    &__contact {
        &-list {
            margin: 0;
            margin-top: 25px;

            li {

                a {
                    position: relative;
                    padding-left: 25px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;

                    >i {
                        color: $thm-secondary;
                        font-size: 15px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                    }
                }
            }
        }
    }

    &__mailchimp {

        &-form {
            margin-top: 13px;
            width: 100%;
            height: 61px;
            background-color: #fff;
            display: flex;
            justify-content: space-between;

            input[type=text],
            input[type=email] {
                border: none;
                outline: none;
                width: 100%;
                color: $thm-color;
                font-size: 14px;
                font-weight: 500;
                padding-left: 20px;
            }

            .thm-btn {
                flex-shrink: 0;
                padding: 15.5px 13px;
                font-size: 18px;
                font-weight: 800;

                &:hover {
                    background-color: $thm-black;
                }
            }
        }
    }
}


.bottom-footer {
    &__inner {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 25px 0;
        padding-right: 50px;

        @include tablet {
            flex-direction: row;
            justify-content: space-between;
            padding: 76/2*1px 0;
            padding-right: 80px;
        }

        &::before {
            content: '';
            width: 1000000px;
            height: 100%;
            background-color: $thm-primary;
            position: absolute;
            right: 100%;
            top: 0;
        }

        &::after {
            content: '';
            height: 100%;
            background-color: $thm-primary;
            transform: skew(-18deg);
            position: absolute;
            top: 0;
            right: 20px;

            width: 110%;
        }

    }

    &__text {
        margin: 0;
        position: relative;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        z-index: 1;

        a {
            color: inherit;
            transition: all 500ms ease;

            &:not(:last-of-type):after {
                content: '/';
                margin-left: 15px;
                margin-right: 10px;
            }

            &:hover {
                color: $thm-secondary;
            }
        }
    }

}