.badge {
    background-color: var(--thm-primary) !important;
}

.badge-inline-row {
    margin: 10px auto;
}

.badge-inline-row .badge {
    font-size: 20px;
    margin: 10px 10px 10px 0;
}

.feature-lists {
    padding-bottom: 80px;
}

@media (min-width: 992px) {
    .feature-lists {
        padding-bottom: 100px;
    }
}

.bg-grey {
    background-color: #ebebeb;
}

.card {
    border: 0;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
}

.dct-input {
    padding: 12px;
}

.dct-btn {
    height: 45px;
    background-color: var(--thm-primary);
    border-color: var(--thm-primary);
    border-radius: 0 !important;
}

.btn-facebook {
    background-color: #3b5998;
    width: 400px;
    max-width: 80vw;
}

.text-dct {
    color: var(--thm-primary);
}

.dct-label {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    color: var(--thm-black);
    margin-bottom: 0 !important;
}

.dct-fields {
    .row {
        margin-left: -10px;
        margin-right: -10px;

        [class*=col-] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    textarea,
    select,
    input {
        width: 100%;
        height: 70px;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        display: block;
        background-color: $thm-gray;

        &::placeholder {
            color: $thm-color !important;
            opacity: 1;
        }
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024"><path fill="rgb(34, 36, 41)" d="M540.1 776.847l472.32-473.63c15.466-15.518 15.44-40.642-0.080-56.134-15.518-15.48-40.656-15.44-56.134 0.080l-444.208 445.438-444.206-445.454c-15.48-15.518-40.602-15.558-56.122-0.080-7.78 7.766-11.67 17.94-11.67 28.114 0 10.148 3.864 20.282 11.59 28.034l472.308 473.632c7.436 7.474 17.556 11.668 28.1 11.668s20.652-4.206 28.102-11.668z"></path></svg>');
        background-repeat: no-repeat;
        background-position: right 30px top 50%;
        background-size: .65em auto;
    }

    textarea {
        height: 170px;
        padding-top: 15px;
    }

    ::placeholder {
        color: inherit;
        opacity: 1;
    }

    .thm-btn {
        font-size: 18px;
        font-weight: 800;
        padding: 20px 48px;
        border: 1px solid var(--thm-base);

        &:hover {
            background-color: 'white';
            color: var(--thm-base);
        }
    }
}

.form-control {
    transition: 200ms;
}

.form-control:focus {
    color: #212529;
    background-color: rgb(240, 245, 255);
    border-color: none;
    outline: 0;
    box-shadow: none;
}

.form-control.is-invalid:focus {
    outline: 0;
    box-shadow: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border: 1px solid #dc3545 !important;
}

.thm-btn {
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    border: 1px solid var(--thm-base);

    &:hover,
    &.filled {
        background-color: white !important;
        color: var(--thm-base) !important;
        border: 1px solid var(--thm-base) !important;
    }

    &.filled {
        cursor: default;
    }
}

.account-section {
    color: var(--thm-grey);
    text-transform: uppercase;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: -5px;
}

.secondary-hr {
    height: 3px !important;
    background-color: var(--thm-secondary);
    border: none;
}


/* ANT OVERRIDE */
.ant-modal-content {
    border-radius: 0;
}

.ant-modal {
    width: 700px !important;
    max-width: 95vw !important;
}

.ant-modal-content {
    border-radius: 0 !important;
    padding: 35px !important;
}

.ant-modal-footer {
    margin-top: 30px !important;
}

.ant-btn {
    min-height: 40px !important;
    min-width: 120px !important;
    border-radius: 0;
    font-family: var(--thm-font);
    font-size: 16px;
}

.ant-btn-danger {
    min-height: 40px !important;
    min-width: 120px !important;
    border-radius: 0;
    font-family: var(--thm-font);
    font-size: 16px;
    background-color: var(--thm-danger) !important;
    border-color: var(--thm-danger) !important;
}

.ant-select-selector {
    height: 100% !important;
    padding: 9px 12px !important;
}

.ant-select,
.ant-input {
    height: 50px;
}

.ant-select-selection-search-input {
    height: 100% !important;
}

[class^="ant-"] {
    font-size: 16px;
}

.helper-text {
    font-size: 14px;

    &__error {
        font-size: 14px;
        color: var(--thm-danger);
    }
}

.btn-disconnect {
    background-color: var(--thm-danger);
    border: 1px solid var(--thm-danger);

    &:hover {
        color: var(--thm-danger) !important;
        background-color: white !important;
    }
}

.ant-select {
    width: 100% !important;
}

.nav-item {
    margin-right: 20px;
    min-width: 20%;

    a {
        width: 100%;
        text-align: center;
        border-radius: 0 !important;
        padding: 15px 25px;
        background-color: #ededed;
        font-family: var(--heading-font);
        font-size: 18px;
        text-transform: uppercase;

        @include max-mobile {
            font-size: 14px;
            padding: 10px 20px;
            margin-bottom: 20px;
        }
    }

    .nav-link {
        color: var(--thm-grey)
    }

    .nav-link.active {
        background-color: var(--thm-primary) !important;
    }
}

.connected-icon {
    width: 30px;
    height: 30px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.ant-checkbox-inner,
.ant-checkbox-input {
    transform: scale(1.5)
}

.online-booking {
    .section-title {
        margin-bottom: 30px !important;
        max-width: 60vw;

        @include max-mobile {
            max-width: 100vw !important;
        }
    }

    .price-card {
        margin-top: 20px;
        height: calc(100% - 20px);
        display: flex;
        flex-direction: column;

        &__bottom {
            display: flex;
            flex-direction: column;
            height: inherit;

            .thm-btn {
                margin: auto auto 0 auto;
                margin-bottom: 0;
                max-width: 200px;
            }
        }
    }
}

.link-btn {
    color: var(--thm-primary);
    text-decoration: underline;
    cursor: pointer;
}

[id^=container-step-] {
    transition: 200ms;
}

[id^=container-step-].disabled {
    // opacity: 0.5;
    // pointer-events: none;
    display: none;
}

.calendar-cell {
    margin-right: 5px;
    margin-left: 5px;
    background-color: var(--thm-gray);

    .day-indicator {
        padding: 10px;
        text-align: center;
        background-color: var(--thm-primary);
        color: white;
        font-weight: bold;
        font-size: 20px;
    }

    .creneau-container {
        padding: 15px;
    }

    .creneau {
        transition: 300ms;
        border: 1px solid white;
        text-align: center;
        padding: 4px;
        font-size: 17px;
        background-color: white;
        margin-bottom: 8px;

        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
            font-size: 15px;
        }
    }

    .creneau:hover {
        cursor: pointer;
    }

    .creneau.filled {
        border: 1px solid var(--thm-base);
        color: var(--thm-base);
    }

    .creneau-afternoon {
        margin-top: 15px;
    }

    .is-week-end {
        background-color: var(--thm-black);
    }
}

.mb-ncard {
    margin-bottom: -25px;

    @include max-mobile {
        margin-bottom: 0 !important;
        margin-top: 20px !important;
    }
}

.total-btn {
    cursor: default;
    pointer-events: none;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.mobile-full-width {
    @include max-mobile {
        width: 100%;
        float: none;
    }
}

@include max-mobile {
    .online-booking__count {
        &.invisible {
            display: none;
        }
    }
}

.confirm-booking-btn {
    min-width: 150px;
}

.custom-ant-card {
    background: var(--thm-gray);

    .ant-card-head {
        border-radius: 0;
        background-color: var(--thm-primary);
        min-height: 45px;
        color: white;
    }

    .ant-card-head-title {
        p {
            margin: 0;
        }
    }

    .online-booking__extra-list {
        margin-top: 0;
    }
}

.thm-btn-danger {
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    background-color: var(--thm-danger) !important;
    border: 1px solid var(--thm-danger) !important;

    &:hover,
    &.filled {
        background-color: white !important;
        color: var(--thm-danger) !important;
        border: 1px solid var(--thm-danger) !important;
    }

    &.filled {
        cursor: default;
    }
}

.badge-danger {
    background-color: var(--thm-danger) !important;
}

.badge-warning {
    background-color: var(--thm-warning) !important;
}

.code-reduc-wrapper {
    margin-right: 0;
    max-width: 330px;
    margin-left: auto;

    @media screen and (max-width: 576px) {
        max-width: 100%;
    }

    .input-wrapper {

        position: relative;

        input {
            height: 50px !important;
            font-size: 15px !important;
            padding: 10px 20px !important;
        }

        .submit-code-btn {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px !important;
            padding: 0 10px;
            font-size: 13px;
            height: 33px;
            margin: auto;
        }
    }
}

.static-page-container {
    h1 {
        font-size: 30px !important;
    }
}

.responsive-comparison-table {
    ul {
        display: flex;
        top: 0px;
        z-index: 10;
        padding-bottom: 14px;
    }

    li {
        list-style: none;
        flex: 1;
    }

    li:last-child {
        border-right: 1px solid #DDD;
    }

    button {
        width: 100%;
        border: 1px solid #DDD;
        border-right: 0;
        border-top: 0;
        padding: 10px;
        background: #FFF;
        font-size: 14px;
        font-weight: bold;
        height: 80px;
        color: #999
    }

    li.active button {
        background: var(--thm-gray);
        color: #000;
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        @include max-mobile {
            table-layout: unset;
        }
    }

    th {
        background: var(--thm-gray);
        display: none;
    }

    .prestation-name {
        color: var(--thm-primary) !important;
        font-weight: 700 !important;
        text-transform: uppercase !important;
        width: 70px;
    }

    td,
    th {
        height: 53px
    }

    td,
    th {
        border: 1px solid #DDD;
        padding: 10px;
        empty-cells: show;
    }

    td,
    th {
        text-align: left;
    }

    td+td,
    th+th {
        text-align: center;
        display: none;
    }

    td.default {
        display: table-cell;
    }

    .bg-blue {
        border-top: 3px solid var(--thm-primary);
    }

    .sep {
        background: var(--thm-gray);
        font-weight: bold;
    }

    .txt-top {
        position: relative;
        font-size: 12px;
        top: -15px;
        left: 3px;
    }

    .tick {
        font-size: 18px;
        color: #2CA01C;
    }

    .hide {
        border: 0;
        background: none;
    }

    @media (min-width: 992px) {
        ul {
            display: none;
        }

        td,
        th {
            display: table-cell !important;
        }

        td,
        th {
            width: 250px;

        }

        td+td,
        th+th {
            width: auto;
        }
    }

    .mobile-ul {
        padding: 0;
    }
}

.select-pricing-btn-book{
    border: none !important;
    .thm-btn{
        height: 50px;
    }
}

.tooltip-trigger{
    color: var(--thm-secondary);
}

.feature-description{
    font-size: 15px;
    line-height: 17px;
}

.txt-l {
    font-size: 28px !important;
    font-weight: 600;
    font-family: var(--thm-font) !important;
    color: var(--thm-black) !important;
}

.option-item{
    max-width: 60%;
    @include max-mobile{
        max-width: unset;
    }
}

.float-right{
    float: right !important;
}